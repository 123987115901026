import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Headroom from 'react-headroom'
import React, { Component } from 'react'
import { FaSearch, FaFacebookSquare, FaBars, FaInstagram, FaYoutubeSquare, FaTwitterSquare } from 'react-icons/fa'
import Helmet from 'react-helmet'
import { split } from 'lodash'
import ListLink from '../ListLink'
import Search from '../Search'
import MarkdownContent from '../MarkdownContent'
import internalLinks from '../../utils/internalLinks'

import './header.css'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTop: true,
      isPageIndex: this.props.index === "true",
      fireSearch: this.props.fireSearch === "true",
      searchActive: false,
      searchQuery: "",
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll, false)
    const [_, queryValue] = split(window.location.search, 'q=')
    if (!queryValue)return null
    const searchDecoded = decodeURIComponent(queryValue)
    if (searchDecoded !== '') {
      this.setState({ searchQuery: searchDecoded, searchActive: true })
    }
  }

  componentDidUpdate() {
    const [_, queryValue] = split(window.location.search, 'q=')
    if (!queryValue)return null
    const searchDecoded = decodeURIComponent(queryValue)
    if (searchDecoded !== '' && searchDecoded !== this.state.searchQuery) {
      this.setState({ searchQuery: searchDecoded, searchActive: true })
    }
  }

  searchClickHandler = () => {
    this.setState((prevState) => {
      return {searchActive: !prevState.searchActive}
    });
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll, false)
  }

  onScroll = () => {
    const isTop = window.scrollY < 30;
    if (isTop !== this.state.isTop) {
      this.setState({ isTop })
    }
  }

  render() {
    const { siteMetadata } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            GetOffers: allMarkdownRemark( sort: { order: ASC, fields: [frontmatter___position]}, filter: { frontmatter: { templateKey: { eq: "offer-page" }}}) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    name
                    position
                  }
                }
              }
            }
            GetIndexPage: markdownRemark( frontmatter: { templateKey: { eq: "index-page" }}) {
              frontmatter {
                title
                description_part_1
                image {
                  childImageSharp {
                    fluid(maxWidth: 650, quality: 70) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            SearchIndexMain: allMarkdownRemark(filter:{frontmatter: {templateKey: {regex: "/^(?!.*(pricing-item|offer-page|material-file|materials-page|pricing-page)).*$/"}}}) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    templateKey
                    title
                    short_description
                    description
                    date
                    description_part_1
                    description_part_2
                    description_part_3
                    tags
                    image {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 70) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
            SearchIndexOffers: allMarkdownRemark(filter:{frontmatter: {templateKey: {regex: "/pricing-item|offer-page/"}}}) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    templateKey
                    name
                    picture {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 70) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                    offer
                    title
                    description
                    quantity
                    price
                    tags
                  }
                }
              }
            }
            SearchIndexMaterials: allMarkdownRemark(filter:{frontmatter: {templateKey: {regex: "/material-file|materials-page/"}}}) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    description
                    name
                    file {
                      name
                    }
                  }
                }
              }
            }
          }
        `
      }
        render={data => {
          return (
          <header className={`header${this.state.isPageIndex ? ' index' : ''}`}>
            <Helmet>
              <html class={this.props.fontSize} />
            </Helmet>
            <div className="header__background" />
            <Headroom className={`${this.state.isTop === true ? ' top' : ''}`}>
              <div className="heaver__navbar--small">
                <FaBars className="header__navbar--toggle" onClick={this.props.drawerClickHandler} />
                <Search searchIndexMain={data.SearchIndexMain} SearchIndexMaterials={data.SearchIndexMaterials} searchIndexOffers={data.SearchIndexOffers} show={this.state.searchActive} blur={this.searchClickHandler} initialQuery={this.state.searchQuery} fire={this.state.fireSearch} />
                <button name="search-icon">
                  <FaSearch className={`header__navbar-links--search${this.state.searchActive ? ' hidden' : ''}`} onClick={this.searchClickHandler} />
                </button>
              </div>
              <h4 className="header__navbar--big">
                {siteMetadata.facebook !== '' && (
                  <li key="fbHeader" className="header__navbar-links--icon">
                    <a className="header__navbar-links--icon" key="fbHeaderLink" href={siteMetadata.facebook}>
                      <FaFacebookSquare className="icon" /><span />
                    </a>
                  </li>
                )}
                {siteMetadata.twitter !== '' && (
                  <li key="twitterHeader" className="header__navbar-links--icon">
                    <a className="header__navbar-links--icon" key="twitterHeaderLink" href={siteMetadata.twitter}>
                      <FaTwitterSquare className="icon" /><span />
                    </a>
                  </li>
                )}
                  {siteMetadata.instagram !== '' && (
                  <li key="instagramHeader" className="header__navbar-links--icon">
                    <a className="header__navbar-links--icon" key="instagramHeaderLink" href={siteMetadata.instagram}>
                      <FaInstagram className="icon" /><span />
                    </a>
                  </li>
                )}
                {siteMetadata.youtube !== '' && (
                  <li key="ytHeader" className="header__navbar-links--icon">
                    <a className="header__navbar-links--icon" key="ytHeaderLink" href={siteMetadata.youtube}>
                      <FaYoutubeSquare className="icon" /><span />
                    </a>
                  </li>
                )}
                <ul className="header__navbar-links">
                  <div className="search-container">
                    <Search searchIndexMain={data.SearchIndexMain} SearchIndexMaterials={data.SearchIndexMaterials} searchIndexOffers={data.SearchIndexOffers} show={this.state.searchActive} blur={this.searchClickHandler} initialQuery={this.state.searchQuery} fire={this.state.fireSearch} />
                    <button name="search-button">
                      <FaSearch className={`header__navbar-links--search${this.state.searchActive ? ' hidden' : ''}`} onClick={this.searchClickHandler} />
                    </button>
                  </div>
                  <ListLink key="strona-glowna" to="/" className="header__navbar-links--regular">Strona główna</ListLink>
                  <div className="header__navbar-dropdown">
                    <div className="header__navbar-dropdown--button">Oferta</div>
                    <div className="header__navbar-dropdown--content">
                      {data.GetOffers.edges.map((edge, index) => (
                        <Link className="header__navbar-dropdown--link" key={edge.node.id} to={edge.node.fields.slug}>{edge.node.frontmatter.name}</Link>
                      ))}
                    </div>
                  </div>
                  {internalLinks().map(link => (
                    <ListLink key={link.key} to={link.to} className="header__navbar-links--regular">{link.label}</ListLink>
                  ))}
                </ul>
              </h4>
            </Headroom>
            <div className="header__content">
              <ul className="header__navbar-fonts">
                <button key="standard" name="font-standard" type="button" className={`header__navbar-links--button ${this.props.fontSize === 'standard' ? ' current' : ''}`} onClick={() => this.props.fontSizeSet('standard')}>A</button>
                <button key="large" name="font-large" type="button" className={`"header__navbar-links--button ${this.props.fontSize === 'large' ? ' current' : ''}`} onClick={() => this.props.fontSizeSet('large')}>A<sup>+</sup></button>
                <button key="extra-large" name="font-extra-large" type="button" className={`"header__navbar-links--button ${this.props.fontSize === 'extra-large' ? ' current' : ''}`} onClick={() => this.props.fontSizeSet('extra-large')}>A<sup>++</sup></button>
              </ul>
              <div className={`header__content--text${this.state.isPageIndex ? ' index' : ''}`}>
                <p className={`header__content--text-title${this.state.isPageIndex ? ' index' : ''}`}>
                  <Link to='/' className={`header__content--text-title${this.state.isPageIndex ? ' index' : ''}`}>{data.GetIndexPage.frontmatter.title}</Link>
                </p>
                {this.state.isPageIndex && (
                  <MarkdownContent content={data.GetIndexPage.frontmatter.description_part_1} className="header__content--text-desc" />
                )}
              </div>
              {this.state.isPageIndex && (
              <Img className="header__content--img" fluid={data.GetIndexPage.frontmatter.image.childImageSharp.fluid} alt="Header" />
              )}
            </div>
            <div className="separator"></div>
          </header>
        )}}
      />
    )
  }
}

export default Header
