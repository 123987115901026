import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import {
  FaFacebookSquare, FaTimes, FaInstagram, FaYoutubeSquare, FaTwitterSquare,
} from 'react-icons/fa'
import internalLinks from '../../utils/internalLinks'

import './sideDrawer.css'

const SideDrawer = ({
  drawerClickHandler, show, data, siteMetadata, fontSize, fontSizeSet,
}) => {
  let drawerClass = 'side-drawer'
  if (show) {
    drawerClass = 'side-drawer open'
  }
  return (
    <nav className={drawerClass}>
      <FaTimes className="side-drawer__btn-close" onClick={drawerClickHandler} />
      <ul className="side-drawer__links">
        <ul className="side-drawer__fonts">
          <button key="standard" type="button" className={`side-drawer__fonts--button ${fontSize === 'standard' ? ' current' : ''}`} onClick={() => fontSizeSet('standard')}>A</button>
          <button key="large" type="button" className={`side-drawer__fonts--button ${fontSize === 'large' ? ' current' : ''}`} onClick={() => fontSizeSet('large')}>A<sup>+</sup></button>
          <button key="extra-large" type="button" className={`side-drawer__fonts--button ${fontSize === 'extra-large' ? ' current' : ''}`} onClick={() => fontSizeSet('extra-large')}>A<sup>++</sup></button>
        </ul>
        <li><Link key="home" to="/" className="side-drawer__link--regular" onClick={drawerClickHandler}>Strona główna</Link></li>
        <li>
          <p>Oferta</p>
        </li>
        {data.GetOffers.edges.map(edge => (
          <li><Link className="side-drawer__link--offer" key={edge.node.id} to={edge.node.fields.slug} onClick={drawerClickHandler}>{edge.node.frontmatter.name}</Link></li>
        ))}
        {internalLinks().map(link => (
          <li><Link key={link.key} to={link.to} className="side-drawer__link--regular" onClick={drawerClickHandler}>{link.label}</Link></li>
        ))}
        {siteMetadata.facebook !== '' && (
        <li key="fbHeader">
          <a className="side-drawer__link--regular" key="fbHeaderLink" href={siteMetadata.facebook}>
            <FaFacebookSquare key="fbSideIcon" className="side-drawer__link--icon" /> <span>Znajdź nas na Facebooku</span>
          </a>
        </li>
        )}
        {siteMetadata.twitter !== '' && (
          <li key="twitterHeader">
            <a className="side-drawer__link--regular" key="twitterHeaderLink" href={siteMetadata.twitter}>
              <FaTwitterSquare key="twitterSideIcon" className="side-drawer__link--icon" /> <span>Śledź nas na Twitterze</span>
            </a>
          </li>
        )}
        {siteMetadata.instagram !== '' && (
        <li key="instagramHeader">
          <a className="side-drawer__link--regular" key="instagramHeaderLink" href={siteMetadata.instagram}>
            <FaInstagram key="instagramSideIcon" className="side-drawer__link--icon" /> <span>Śledź nas na Instagramie</span>
          </a>
        </li>
        )}
        {siteMetadata.youtube !== '' && (
        <li key="ytHeader">
          <a className="side-drawer__link--regular" key="ytHeaderLink" href={siteMetadata.youtube}>
            <FaYoutubeSquare key="ytSideIcon" className="side-drawer__link--icon" /> <span>Subskrybuj na YouTube</span>
          </a>
        </li>
        )}
      </ul>
    </nav>
  )
}

export default ({
  drawerClickHandler, show, siteMetadata, fontSize, fontSizeSet,
}) => (
  <StaticQuery
    query={graphql`
    query {
      GetOffers: allMarkdownRemark( sort: { order: ASC, fields: [frontmatter___position]}, filter: { frontmatter: { templateKey: { eq: "offer-page" }}}) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              name
            }
          }
        }
      }
    }
  `
  }
    render={data => (
      <SideDrawer
        drawerClickHandler={drawerClickHandler}
        show={show}
        data={data}
        siteMetadata={siteMetadata}
        fontSize={fontSize}
        fontSizeSet={fontSizeSet}
      />
    )}
  />
)
