import { Link, graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import {
  FaPhone, FaEnvelope, FaFacebookSquare, FaInstagram, FaYoutubeSquare, FaTwitterSquare,
  FaFacebookMessenger,
} from 'react-icons/fa'
import ListLink from '../ListLink'
import internalLinks from '../../utils/internalLinks'

import './footer.css'

const Footer = ({ data, siteMetadata }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__column--regular">
          <ul className="footer__column-links">
            <li key="siteTitle">
              <Link className="footer__column-links--regular" key="siteTitleLink" to="/" dangerouslySetInnerHTML={{ __html: siteMetadata.title }} />
            </li>
            {siteMetadata.facebook !== '' && (
            <li key="fbFooter">
              <a className="footer__column-links--regular" key="fbFooterLink" href={siteMetadata.facebook}>
                <FaFacebookSquare key="fbFooterIcon" className="footer__column-links--social" /> <span>Znajdź nas na Facebooku</span>
              </a>
            </li>
            )}
            {siteMetadata.twitter !== '' && (
              <li key="twitterFooter">
                <a className="footer__column-links--regular" key="twitterFooterLink" href={siteMetadata.twitter}>
                  <FaTwitterSquare className="footer__column-links--social" /> <span>Śledź nas na Twitterze</span>
                </a>
              </li>
            )}
            {siteMetadata.instagram !== '' && (
            <li key="instagramFooter">
              <a className="footer__column-links--regular" key="instagramFooterLink" href={siteMetadata.instagram}>
                <FaInstagram className="footer__column-links--social" /> <span>Śledź nas na Instagramie</span>
              </a>
            </li>
            )}
            {siteMetadata.youtube !== '' && (
            <li key="ytFooter">
              <a className="footer__column-links--regular" key="ytFooterLink" href={siteMetadata.youtube}>
                <FaYoutubeSquare className="footer__column-links--social" /> <span>Subskrybuj na YouTube</span>
              </a>
            </li>
            )}
          </ul>
        </div>
        <div className="footer__column--small">
          <ul className="footer__column-links">
            <li key="quickLinksTitle" className="footer__column-links--top">
              Na skróty
            </li>
            {internalLinks().map(link => (
              <ListLink key={link.key} to={link.to} className="footer__column-links--regular">{link.label}</ListLink>
            ))}
          </ul>
        </div>
        <div className="footer__column--regular">
          <ul className="footer__column-links">
            <li key="ContactTitle" className="footer__column-links--top">
              Dane kontaktowe
            </li>
            <li key="phone" className="footer__column-links--text">
              <FaPhone key="phoneIcon" className="footer__column-links--icon" />
              <a href={`tel:${frontmatter.phone_number}`}>{frontmatter.phone_number}</a> <br />
              <p key="phoneText" className="footer__column-links--phone-line">{frontmatter.phone_number_2}</p>
            </li>
            <li key="mail">
              <FaEnvelope key="mailIcon" className="footer__column-links--mail-icon" />{' '} <a key="mailLink" className="footer__column-links--other" href={`mailto:${frontmatter.e_mail}`}>{frontmatter.e_mail}</a>
            </li>
            {frontmatter.messenger && <li key="messenger">
              <a href={frontmatter.messenger} target='blank'><FaFacebookMessenger className="footer__column-links--social" /> <span>{frontmatter.messenger}</span></a>
            </li> }
          </ul>
        </div>
      </div>
      <div className="footer__bottom">
        <hr className="footer__bottom-divider" />
        <p className="footer__bottom-paragraph">
          © {new Date().getFullYear()} {' '}
          <a className="footer__column-links--other" href="https://mathima.pl/">
            Tokalo
          </a>
          , wszelkie&nbsp;prawa&nbsp;zastrzeżone
        </p>
        <p className="footer__bottom-paragraph">
          <a className="footer__column-links--other" href="https://infovation.pl/">
            Realizacja infovation.pl
          </a>
        </p>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default ({ siteMetadata }) => (
  <StaticQuery
    query={graphql`
    query {
      markdownRemark( frontmatter: { templateKey: { eq: "contact-page" }}) {
        frontmatter {
          phone_number
          e_mail
          messenger
        }
      }
    }
    `
  }
    render={data => <Footer data={data} siteMetadata={siteMetadata} />}
  />
)
