/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import './backdrop.css'

const Backdrop = props => (
  <div className="backdrop" onClick={props.click} />
)

export default Backdrop
