import React from 'react'
import { Link } from 'gatsby'

const ListLink = ({
  to, children, className
}) => (
  <li className={className}>
    <Link className={className} to={to}>{children}</Link>
  </li>
)

export default ListLink
