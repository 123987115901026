function internalLinks() {
  const links = [
    { key: 'news', label: 'Aktualności', to: '/aktualnosci' },
    { key: 'pricing', label: 'Cennik', to: '/cennik' },
    { key: 'contact', label: 'Kontakt', to: '/kontakt' },
    { key: 'materials', label: 'Materiały', to: '/materialy' },
  ]
  return links
}

module.exports = internalLinks
