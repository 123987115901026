import React, { Component } from "react"
import { Link, navigate } from 'gatsby'
import { filter, includes, isEmpty, debounce } from 'lodash'

import './search.css'

export default class Search extends Component {
  constructor(props) {
    super(props)
    this.pages = []
    this.state = {
      query: '',
      results: [],
    }
  }

  getLink = node => {
    if (node.frontmatter.title === 'Kontakt') {
      return '/kontakt'
    }
    return node.fields.slug
  }

  getText = frontmatter => {
    if (frontmatter.templateKey === 'index-page') {
      return 'Strona główna'
    }
    return frontmatter.title || frontmatter.name
  }

  getDescription = frontmatter => {
     return frontmatter.description_part_1 || frontmatter.short_description || frontmatter.description
  }

  getPages = () => {
    if (!isEmpty(this.pages)) return this.pages
    let pages = this.props.searchIndexMain.edges.map(edge => {
      const { frontmatter } = edge.node
      let content = [
        'title',
        'short_description',
        'description',
        'date',
        'tags',
        'description_part_1',
        'description_part_2',
        'description_part_3',
      ].map(a => frontmatter[a]).filter(Boolean).join(' ').toLowerCase()

      return {
        content,
        description: this.getDescription(edge.node.frontmatter),
        templateKey: frontmatter.templateKey,
        thumbnail: frontmatter.image,
        link: this.getLink(edge.node),
        label: this.getText(edge.node.frontmatter),
        id: edge.node.id,
      }
    })

    const allOffers = this.props.searchIndexOffers.edges.filter(offer => offer.node.frontmatter.templateKey === 'offer-page')
    const offersContents = allOffers.map(offer => {
      const { frontmatter } = offer.node
      let offersPricing = this.props.searchIndexOffers.edges.filter(pricing => frontmatter.name === pricing.node.frontmatter.offer)
      let content = [
        'name',
        'description',
        'tags'
      ].map(a => frontmatter[a]).filter(Boolean).join(' ').toLowerCase()
      offersPricing.map(pricing => {
        const { frontmatter } = pricing.node
        content += [
          'offer',
          'quantity',
          'price',
          'description'
        ].map(a => frontmatter[a]).filter(Boolean).join(' ').toLowerCase()
      })
      return {
        content,
        description: frontmatter.description,
        templateKey: frontmatter.templateKey,
        thumbnail: frontmatter.picture,
        link: this.getLink(offer.node),
        label: this.getText(offer.node.frontmatter),
        id: offer.node.id,
      }
    })

    let materialsContent = ""
    this.props.SearchIndexMaterials.edges.map(edge => {
      const { frontmatter } = edge.node
      materialsContent += [
        'title',
        'description',
        'name',
        'file.name',
      ].map(a => frontmatter[a]).filter(Boolean).join(' ').toLowerCase()
    })

    const materialsPage = {
      content: materialsContent,
      templateKey: 'materials-page',
      link: '/materialy',
      label: 'Materiały',
      id: 'materialy',
    }
    this.pages = [...pages, ...offersContents, materialsPage]

    return this.pages
  }
  search = (text, key) => {
    if(key == 'Enter') {
      const query = text.toLowerCase()
      if (query.length > 2) {
        const results = filter(this.getPages(), (page) => includes(page.content, query))
        navigate(`/szukaj?q=${query}`, { state: { results: results }}, { replace: true } )
      }
    }
  }

  searchDebounced = debounce(text => {
    if(text !== this.props.initialQuery && text !== this.props.initialQuery + ' ') {
      this.search(text, 'Enter')
    }
  }, 300)

  render() {
    let searchClass = 'search__results'
    if (this.props.show) {
      this.searchInput.focus()
      searchClass = 'search__results open'
      this.searchInput.value = this.props.initialQuery
      if (this.props.fire) {
        this.search(this.searchInput.value, 'Enter')
      }
    }

    return (
      <div className={searchClass}>
        <input ref={(input) => { this.searchInput = input; }} className={`search__input${this.props.show ? ' open' : ''}`} type="text" onChange={e => this.searchDebounced(e.target.value)} onKeyPress={e => this.search(e.target.value, e.key)} placeholder="Wyszukaj i wybierz (min. 3 znaki)" onBlur={this.props.blur} />
      </div>
    )
  }
}
