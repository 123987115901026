/* eslint-disable react/jsx-filename-extension */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({
  title, description, pathname, lang, meta, keywords,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle
            titleTemplate
            defaultDescription
            defaultKeywords
            siteUrl
          }
        }
      }
    `,
  )

  const seo = {
    title: title || site.siteMetadata.defaultTitle,
    description: description || site.siteMetadata.defaultDescription,
    keywords: [keywords, site.siteMetadata.defaultKeywords].join(', '),
    url: `${site.siteMetadata.siteUrl}${pathname || '/'}`,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={site.siteMetadata.titleTemplate}
      meta={[
        {
          name: 'description',
          content: seo.description,
        },
        {
          property: 'og:title',
          content: seo.title,
        },
        {
          property: 'og:description',
          content: seo.description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:title',
          content: seo.title,
        },
        {
          name: 'twitter:description',
          content: seo.description,
        },
        {
          name: 'robots',
          content: 'index',
        },
      ]
        .concat(
          seo.keywords.length > 0
            ? {
              name: 'keywords',
              content: seo.keywords,
            }
            : [],
        )
        .concat(meta)}
    >
      <html lang="pl-PL" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'pl',
  meta: [],
  keywords: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.string,
  title: PropTypes.string,
}


export default SEO
