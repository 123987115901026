import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery, Link } from 'gatsby'
import CookieConsent from "react-cookie-consent";

import Helmet from 'react-helmet'
import Header from '../Header'
import Footer from '../Footer'
import Backdrop from '../Backdrop'
import SideDrawer from '../SideDrawer'

import './layout.css'

class Layout extends Component {
  constructor(props) {
    super(props)
    let fontSizeDefault = 'standard'
    if (typeof window !== 'undefined') {
      fontSizeDefault = localStorage.getItem('fontSize') || 'standard'
    }
    this.state = {
      fontSize: fontSizeDefault,
      menuOpen: false
    }
  }

  drawerToggleButtonHandler = () => {
    this.setState((prevState) => {
      return {menuOpen: !prevState.menuOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({ menuOpen: false })
  }

  fontSizeSet = (size) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('fontSize', size)
    }
    this.setState({ fontSize: size})
  }

  render() {
    const { fontSize } = this.state

    let backdrop;

    if (this.state.menuOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />
    }

    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
                twitter
                youtube
                instagram
                facebook
              }
            }
          }
        `
      }
        render={data => (
          <div className="container">
            <Header drawerClickHandler={this.drawerToggleButtonHandler} siteMetadata={data.site.siteMetadata} fontSize={fontSize} fontSizeSet={this.fontSizeSet} index={this.props.index} fireSearch={this.props.fireSearch} />
            <SideDrawer drawerClickHandler={this.drawerToggleButtonHandler} show={this.state.menuOpen} siteMetadata={data.site.siteMetadata} fontSize={fontSize} fontSizeSet={this.fontSizeSet} />
            {backdrop}
            <div className="container__inner">
              {this.props.children}
            </div>
            <Footer siteMetadata={data.site.siteMetadata} />
            <CookieConsent
              location="bottom"
              buttonText="Akceptuję"
              cookieName="polityka-cookies-mathima"
              style={{ background: "#2f80ed" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              W ramach naszej strony stosujemy pliki cookies, w celu: zapewnienia użytkownikom większej funkcjonalności i użyteczności oraz w celach statystycznych. Możesz określić warunki przechowywania lub dostępu do plików cookies w Twojej przeglądarce.
              Nasza {<Link to="/polityka-prywatnosci">Polityka prywatności.</Link>}
            </CookieConsent>
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
