import React from 'react'
import PropTypes from 'prop-types'
import showdown from 'showdown'

const converter = new showdown.Converter()

const MarkdownContent = ({ content, className }) => (
  <div className={`${className} markdown`} dangerouslySetInnerHTML={{ __html: converter.makeHtml(content).replace(/(\s)([\S])[\s]+/g,"$1$2&nbsp;") }} />
)

MarkdownContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}

MarkdownContent.defaultProps = {
  content: '',
  className: '',
}

export default MarkdownContent
